@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .meshBG {
      background-color:hsla(161,19%,60%,1);
      background-image:
      radial-gradient(at 40% 20%, hsla(161,19%,60%,1) 0px, transparent 50%),
      radial-gradient(at 0% 100%, hsla(201,43%,78%,1) 0px, transparent 50%),
      radial-gradient(at 80% 100%, hsla(180,11%,89%,1) 0px, transparent 50%),
      radial-gradient(at 0% 0%, hsla(40,40%,89%,1) 0px, transparent 50%);
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
